import logo from './logo1000.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="content">
        <h2 className='heading'>Luke Mullan</h2>
        <p>Recording | Mixing | Mastering</p>
      </div>
      <img src={logo} className="App-logo" alt="logo" />
      <div className='App-header'>
        <p>website coming soon</p>
      </div>
    </div>
  );
}

export default App;
